const { villageCoeffDefaults } = require('./villageCoeffDefaults');

export const basicComponents = [
  {
    type: 'input',
    icon: 'far fa-keyboard',
    options: {
      width: '100%',
      defaultValue: '',
      required: false,
      dataType: 'string',
      pattern: '',
      patternMessage: '',
      placeholder: '',
      disabled: false,
      convertNumberToText: false,
      sumNumbers: false,
      multiplyNumbers: false,
      diffMonths: false,
      hideLabel: false,
      showTooltip: false
    }
  },
  {
    type: 'textarea',
    icon: 'fas fa-align-left',
    options: {
      width: '100%',
      defaultValue: '',
      required: false,
      disabled: false,
      pattern: '',
      patternMessage: '',
      placeholder: '',
      hideLabel: false,
      rows: '',
      maxRows: '',
      plaintext: false,
    }
  },
  {
    type: 'number',
    icon: 'fas fa-sort-numeric-up-alt',
    options: {
      width: '',
      required: false,
      defaultValue: 0,
      min: '',
      max: '',
      step: 1,
      disabled: false,
      convertNumberToText: false,
      sumNumbers: false,
      multiplyNumbers: false,
      diffMonths: false,
      controlsPosition: '',
      hideLabel: false,
    }
  },
  {
    type: 'radio',
    icon: 'far fa-dot-circle',
    options: {
      inline: false,
      defaultValue: '',
      showLabel: false,
      options: [
        {
          value: 'Option 1',
          label: 'Option 1'
        },
        {
          value: 'Option 2',
          label: 'Option 2'
        },
        {
          value: 'Option 3',
          label: 'Option 3'
        }
      ],
      required: false,
      width: '',
      remote: false,
      remoteOptions: [],
      props: {
        value: 'value',
        label: 'label'
      },
      remoteFunc: '',
      disabled: false,
      hideLabel: false,
    }
  },
  {
    type: 'checkbox',
    icon: 'far fa-check-square',
    options: {
      inline: false,
      defaultValue: [],
      showLabel: false,
      options: [
        {
          value: 'Option 1'
        },
        {
          value: 'Option 2'
        },
        {
          value: 'Option 3'
        }
      ],
      required: false,
      width: '',
      remote: false,
      remoteOptions: [],
      props: {
        value: 'value',
        label: 'label'
      },
      remoteFunc: '',
      disabled: false,
      hideLabel: false,
    }
  },
  {
    type: 'date',
    icon: 'far fa-calendar-alt',
    options: {
      defaultValue: '',
      readonly: false,
      disabled: false,
      editable: true,
      clearable: true,
      placeholder: '',
      startPlaceholder: '',
      endPlaceholder: '',
      type: 'date',
      format: 'yyyy-MM-dd',
      timestamp: false,
      required: false,
      width: '',
      hideLabel: false,
      sumMonths: false,
    }
  },
  {
    type: 'select',
    icon: 'far fa-check-circle',
    options: {
      defaultValue: '',
      multiple: false,
      disabled: false,
      clearable: false,
      placeholder: '',
      required: false,
      showLabel: false,
      width: '',
      options: [
        {
          value: 'Option 1'
        },
        {
          value: 'Option 2'
        },{
          value: 'Option 3'
        }
      ],
      remote: false,
      filterable: false,
      remoteOptions: [],
      props: {
        value: 'value',
        label: 'label'
      },
      remoteFunc: '',
      hideLabel: false,
    }
  },
  {
    type: 'switch',
    icon: 'fas fa-toggle-on',
    options: {
      defaultValue: false,
      required: false,
      disabled: false,
      hideLabel: false,
    },
  },
  {
    type: 'text',
    icon: 'fas fa-font',
    options: {
      defaultValue: 'This is a text',
      customClass: '',
      isBold: false,
      fontSize: '12',
      hideLabel: false,
    },
  },
  {
    type: 'html',
    icon: 'fab fa-html5',
    options: {
      defaultValue: '',
      width: 100,
      hideLabel: false,
    },
  },
  {
    type: 'divider',
    icon: 'fas fa-minus',
    options: {
      customClass: '',
    }
  }
]

export const advanceComponents = [
  {
    type: 'file',
    icon: 'far fa-file',
    options: {
      disabled: false,
      max: 10,
      maxFileSize: 50,
      acceptedFileTypes: [],
      required: false,
    }
  },
  {
    type: 'editor',
    icon: 'fas fa-edit',
    options: {
      defaultValue: '',
      width: '',
      required: false
    }
  },
]

export const layoutComponents = [
  {
    type: 'grid',
    icon: 'fas fa-grip-horizontal',
    columns: [
      {
        span: 12,
        list: []
      },
      {
        span: 12,
        list: []
      }
    ],
    options: {
      gutter: 0,
      justify: 'start',
      align: 'top'
    }
  },
  {
    type: 'section',
    icon: 'far fa-window-maximize',
    options: {
      collapsible: false,
      isCollapsed: false,
      calculator: false,
      title: 'Section'
    },
    list: [],
  },
  {
    type: 'inline',
    icon: 'fas fa-columns',
    options: {
      title: 'Inline'
    },
    list: [],
  },
]

export const customComponents = [
    {
        type: "beneficiary-list",
        icon: "fas fa-puzzle-piece",
        isCustom: true,
    },
    {
        type: "real-estate-list",
        icon: "fas fa-puzzle-piece",
        isCustom: true,
    },
    {
        type: "applicant-form",
        icon: "fas fa-puzzle-piece",
        isCustom: true,
    },
    {
        type: "decision-list",
        icon: "fas fa-puzzle-piece",
        isCustom: true,
    },
    {
        type: "attachments",
        icon: "fas fa-puzzle-piece",
        isCustom: true,
        options: {
            maxFiles: 10,
            maxFileSize: 10,
            acceptedFileTypes: [],
        },
    },
    {
        type: "urbanism-regulation-picker", // Urbanism regulations picker
        icon: "fas fa-puzzle-piece",
        isCustom: true,
    },
    {
        type: "related-application",
        icon: "fas fa-puzzle-piece",
        isCustom: true,
        options: {
            maxFiles: 1,
            docKey: '',
            customNotFoundMessage: '',
            autocompleteForm: false,
            loadGuestApplications: false,
            loadExternalDocuments: false,
            loadAllOrganisationsApplications: false,
            maxFileSize: 50,
            acceptedFileTypes: [
                {
                    value: "image/jpeg",
                    text: "JPG",
                },
                {
                    value: "application/pdf",
                    text: "PDF",
                },
                {
                    value: "image/png",
                    text: "PNG",
                },
                {
                    value: "application/zip",
                    text: "ZIP",
                },
                {
                    value: "shp",
                    text: "SHP",
                },
            ],
        },
    },
    {
      type: "offender-list",
      icon: "fas fa-puzzle-piece",
      isCustom: true,
    },
    {
      type: "witness-list",
      icon: "fas fa-puzzle-piece",
      isCustom: true,
    },
    {
      type: "realty-list",
      icon: "fas fa-puzzle-piece",
      isCustom: true,
    },
    {
      type: "staff-form",
      icon: "fas fa-puzzle-piece",
      isCustom: true,
    },
    {
        type: "gis-attachments",
        icon: "fas fa-puzzle-piece",
        isCustom: true,
        options: {
            maxFiles: 10,
            maxFileSize: 10,
            acceptedFileTypes: [],
        },
    },
    {
        type: "ac-tax-validator",
        icon: "fas fa-puzzle-piece",
        isCustom: true,
        options: {
            coeff: villageCoeffDefaults,
            acTaxCategories: {
                TAX_A: 1250,
                TAX_B: 750,
                TAX_C: 500,
                TAX_OTHER: 1750,
            }
      },
    },
    {
        type: "locality-list",
        icon: "fas fa-puzzle-piece",
        isCustom: true,
    },
    {
        type: "buildings-list",
        icon: "fas fa-puzzle-piece",
        isCustom: true,
    },
    {
        type: "urbanism-regulations-list",
        icon: "fas fa-puzzle-piece",
        isCustom: true,
        options: {
            showUtrDocumentTemplates: true,
            showRuScopes: true,
        },
    },
];
